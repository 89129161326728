.root {
  width: 419px;
  height: min-content;
  flex-direction: column;
  padding: 30px;
  border-radius: 18px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
}
.frame16 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 50px;
}
.frame2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}
.pIX {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame20 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.pIXCode {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame21 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._1234567890123456 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame212 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame213 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r250032 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame22 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fff;
}
.transfer {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.transfer:disabled {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
  background: white;
}

.transfer:hover{
  color:black;
 
}
.frame3 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}
.onChain {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame202 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.wallet {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame214 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._12345678901234562 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame215 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount2 {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame216 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r2500322 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame23 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 5px;
}
.payOutToken {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 127px;
  height: min-content;
  flex-direction: column;
}
.frame217 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.uSDC {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame222 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._500USDCNet {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame223 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fff;
}
.transfer2 {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame4 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}
.bRLAAccount {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame203 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.accountID {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame218 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._12345678901234563 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame219 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount3 {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame2110 {
  width: 207px;
  height: min-content;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r2500323 {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame224 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fff;
}
.transfer3 {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}


.inputs:focus {
  outline: none;
}


.pixKeyTypeButton {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  border-radius: 6.75px;

 
}

.pixKeyTypeButtonActive {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background:#00dc84
 
}

.pixKeyTypeButton:hover {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background: #00dc84 !important;
 
}

.pixKeyTypeButton:active {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background: #00dc84 !important;
 
}

.pixKeyTypeButton:visited {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background: #00dc84 !important;
 
}

.pixKeyTypeButton:link {
  color:white;
  border: 1px solid white;
  margin-left: 10px;
  padding: 5px;
  width: 66px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background: #00dc84 !important;
 
}


.frame32 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

._12345678901234562 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.frame232 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 5px;
}

.sendToken {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 101px;
  height: min-content;
  flex-direction: column;
}

._500USDCNet {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.sendPayment2 {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}