.root {
  width: 100%;
  height: min-content;
  flex-direction: column;
  overflow: hidden;
}
.navigation {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 141px;
  padding: 15px 4px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.frame4 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px;
}
.logo {
  width: 134.3138px;
  height: 30px;
  background-image:/* webpackIgnore: true */ url('/assets/logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.carbonMenu {
  flex: 1;
  align-self: end;
  display: flex;
  justify-content: right;
  height: '50px';
  width: '50px';
  
}
.icon {
  width: 100%;
  height: 100%;
}
.section {
  height: min-content;
  align-self: stretch;
  padding: 30px 31px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.ourVisionIsToBeTheLargestInfra {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.section2 {
  height: min-content;
  align-self: stretch;
  padding: 50px 31px;
  background-color: #0a1b23;
  background-image:/* webpackIgnore: true */ url('/assets/section2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame35 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  gap: 15px;
}
.ourMissionIsToBringProsperityT {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.bRLADigitalIsATechnologyCompan {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.section4 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
  padding: 30px 0;
  background-color: #fff;
}
.heading2Team {
  color: #0a1b23;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  height: 35px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.luizSemFundo1 {
  position: absolute;
  left: calc(50% - -0.4583px);
  top: 3px;
  width: 368px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/luizSemFundo1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
}
.heading3LuizCasteloBranco {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 93px;
  top: 373px;
  width: 184.728px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading5 {
  position: absolute;
  left: 50%;
  top: calc(50% - -196.805px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.cTO {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link2 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.lucasSemFundo1 {
  position: absolute;
  left: 17px;
  top: 3.61px;
  width: 352.8571px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/lucasSemFundo1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading3LucasGiorgio {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 129px;
  top: 373px;
  width: 128.424px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading52 {
  position: absolute;
  left: calc(50% - -8px);
  top: calc(50% - -195.415px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.cBDO {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link3 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.hectorRemovebgPreview11 {
  position: absolute;
  left: 31px;
  top: 3.61px;
  width: 332.5831px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/hectorRemovebgPreview11.jpg');
  background-position: 50% 20% ;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading3HectorFardin {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 134px;
  top: 373px;
  width: 127.272px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading53 {
  position: absolute;
  left: calc(50% - -12px);
  top: calc(50% - -196.415px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.cOO {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link4 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.matheusSemFundo1 {
  position: absolute;
  left: 13px;
  top: 3px;
  width: 368px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/matheusSemFundo1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading3MatheusMoura {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 123px;
  top: 373px;
  width: 148.056px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading54 {
  position: absolute;
  left: calc(50% - -12px);
  top: calc(50% - -196.805px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.coCEO {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link5 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.leandroSemFundo1 {
  position: absolute;
  left: 30px;
  top: 3px;
  width: 329.6476px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/leandroSemFundo1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover ;
}
.heading3LeandroNoel {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 132px;
  top: 373px;
  width: 125.94px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading55 {
  position: absolute;
  left: calc(50% - -10px);
  top: calc(50% - -196.805px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.coCEO2 {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link6 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.caioSemFundo1 {
  position: absolute;
  left: 43px;
  top: 3.61px;
  width: 368px;
  height: 361px;
  background-image:/* webpackIgnore: true */ url('https://brla.digital/static/media/caioSemFundo1.jpg');
  background-position: 50% 45% ;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading3CaioElYanes {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 132px;
  top: 373px;
  width: 125.304px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading6 {
  position: absolute;
  left: calc(50% - -10px);
  top: calc(50% - -196.415px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.productManager {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.link7 {
  position: relative;
  width: 370px;
  height: 411.39px;
  align-items: flex-start;
}
.heading3EricBastos {
  color: #0a1b23;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  position: absolute;
  left: 133px;
  top: 373px;
  width: 102.528px;
  height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading56 {
  position: absolute;
  left: 50%;
  top: calc(50% - -197.025px);
  width: 216px;
  height: 17px;
  padding: 2px 0 0;
  border-radius: 10px;
  background-color: #0a1b23;
  transform: translate(-50%, -50%);
}
.techManager {
  color: #fff;
  font-size: 11.5px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image3 {
  position: absolute;
  left: calc(50% - 0.304px);
  top: 23.22px;
  width: 340px;
  height: 340px;
  background:/* webpackIgnore: true */ url('https://brla.digital/static/media/image3.jpg');
  background-position: 50% 0% ;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
}
.section6 {
  height: min-content;
  align-self: stretch;
  padding: 11px 0 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.frame14 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 31px;
}
.contact {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  width: 296px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.form {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.inputMobileWidth {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 139px 0 0;
  overflow: hidden;
}
.name {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth2 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder2 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 135px 0 0;
  overflow: hidden;
}
.phone {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth3 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder3 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 117px 0 0;
  overflow: hidden;
}
.company {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth4 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder4 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 134px 0 0;
  overflow: hidden;
}
.eMail {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.textarea {
  position: relative;
  height: 150px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.button {
  position: relative;
  width: 73.67px;
  height: 34px;
  align-items: flex-start;
  padding: 10px 10.67px 10px 9px;
  border-radius: 10px;
  background-color: #00dc84;
}
.submit {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: absolute;
  left: 9px;
  top: 10px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.divHs_cos_wrapper_module_16619 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 11px;
}
.followBRLAOnSocial {
  color: #fff;
  font-size: 12.5px;
  line-height: 20px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divSocialGrid {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 0 96px 0 0;
}
.frame {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.frame2 {
  width: 21.88px;
  height: 25px;
  overflow: visible;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.eMailInfoBrlaDigital {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.phone55113777742 {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.addressRuaUrussui125Cj84SaoPau {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.digitalAssetsAreSubjectToANumb {
  color: #a7a3b5;
  font-size: 10px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
._2023BRLADigital {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: 12px;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
}
.frame15 {
  height: min-content;
  align-self: stretch;
  padding: 10px;
}
.logocoin {
  width: 70px;
  height: 70px;
  background-image:/* webpackIgnore: true */ url('/assets/logocoin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
