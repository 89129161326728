.root {
  width: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0px 18px 54px 0px #0000002d;
}
.frame9 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px;
}
.headerLinkHomeLogo2a621879dbc6 {
  width: 252px;
  height: 77.03px;
  background-image:/* webpackIgnore: true */ url('/assets/headerLinkHomeLogo2a621879dbc6.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame7 {
  width: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 50px 0;
}
.link {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}

.link:hover {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
  background:black
}


.spanInner {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.tablerHome {
  width: 30px;
  height: 30px;
}
.icon {
  width: 100%;
  height: 100%;
}
.home {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link2 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner2 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.clarityTwoWayArrowsLine {
  width: 30px;
  height: 30px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.transactions {
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link3 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner3 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.streamlineMoneyWalletMoneyPaym {
  width: 30px;
  height: 30px;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.payments {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.link4 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner4 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.fontistoMoneySymbol {
  width: 30px;
  height: 30px;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.assetTreasury {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.frame8 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: flex-end;
  align-items: center;
  gap: 21px;
}
.link5 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner5 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 5.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  transform: translateY(-50%);
}
.img {
  width: min-content;
  height: min-content;
  overflow: hidden;
}
.frame {
  width: 30px;
  height: 30px;
  overflow: visible;
}
.icon5 {
  width: 100%;
  height: 100%;
}
.settings {
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link6 {
  position: relative;
  width: 215px;
  height: 37.75px;
  align-items: flex-start;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner6 {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 50%;
  height: 33.75px;
  align-items: flex-start;
  border-radius: 6.75px;
  transform: translateY(-50%);
}
.spanCbAvatar {
  position: absolute;
  left: 11.25px;
  top: 50%;
  width: 20.25px;
  height: 20.25px;
  border-radius: 9999px;
  background-color: #fff;
  transform: translateY(-50%);
}
.img2 {
  position: absolute;
  left: 187.75px;
  top: calc(50% - -0.005px);
  width: 12px;
  height: 12px;
  align-items: flex-start;
  overflow: hidden;
  transform: translateY(-50%);
}
.frame2 {
  position: absolute;
  left: 50%;
  top: 0;
  width: 12px;
  height: 12px;
  overflow: visible;
  transform: translateX(-50%);
}
.icon6 {
  width: 100%;
  height: 100%;
}
