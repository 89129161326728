.root {
  width: 100%;
  height: min-content;
  flex-direction: column;
}
.header {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  padding: 15px 20px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.logo {
  width: 134.3138px;
  height: 30px;
  background-image: url('../../../public/assets/logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ulMainNavigation {
  flex: 1;
  align-self: stretch;
  place-content: flex-end;
  align-items: center;
  gap: 15px;
  padding: 0 0 1px;
}
.home {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.home:hover {
  color: #00dc84;
  transition: 0.2s;
}
.about {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.about:hover {
  color: #00dc84;
  transition: 0.2s;
}
.login {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.login:hover {
  color: #00dc84;
  transition: 0.2s;
}
.sIGNUP {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.sIGNUP:hover {
  color: #00dc84;
  transition: 0.2s;
}
.aPIREFERENCE {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.aPIREFERENCE:hover {
  color: #00dc84;
  transition: 0.2s;
}
.cONTACT {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.cONTACT:hover {
  color: #00dc84;
  transition: 0.2s;
}
.section1 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 50px;
  padding: 20px 44px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.frame39 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 20px;
}
.bRLAToken {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.bRLAIsABRLPeggedStablecoinCrea {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 500px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame40 {
  width: 300px;
  height: min-content;
  align-items: center;
  gap: 20px;
}
.frame2 {
  height: min-content;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: 36px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame1 {
  height: min-content;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.checkTheWhitepaper {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: 36px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame41 {
  position: relative;
  width: 692px;
  height: 459px;
  align-items: center;
}
.tela_home {
  position: absolute;
  left: calc(50% - 85.1478px);
  top: calc(50% - 98.5px);
  width: 461.7045px;
  height: 250px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_swap {
  position: absolute;
  left: calc(50% - -82.4426px);
  top: calc(50% - -86.5px);
  width: 468.8853px;
  height: 250px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.logocoin {
  position: absolute;
  left: calc(50% - 177px);
  top: calc(50% - -99.5px);
  width: 250px;
  height: 250px;
  background-image: url('../../../public/assets/logocoin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section2 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  padding: 50px 100px;
  background-color: #fff;
}
.frame43 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 20px;
}
.transparentAndSecure {
  color: #0a1b23;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.allTheBRLATokensAreStrictlyBac {
  color: #0a1b23;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.frame12 {
  width: 150px;
  height: min-content;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.transparencyPage {
  color: #0a1b23;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: 56px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame42 {
  position: relative;
  height: 270px;
  flex: 1;
  align-items: flex-start;
  padding: 10px;
}
.image1 {
  position: absolute;
  left: calc(50% - -0.5px);
  top: 10px;
  width: 250px;
  height: 250px;
  background-image: url('../../../public/assets/image1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
}
.section3 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  padding: 50px 100px;
  background-color: #0a1b23;
}
.frame45 {
  position: relative;
  height: 300px;
  flex: 1;
  align-items: flex-start;
}
.image2 {
  position: absolute;
  left: calc(50% - -0.5px);
  top: 0;
  width: 300px;
  height: 300px;
  background-image: url('../../../public/assets/image2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
}
.frame44 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}
.accessTheBestOfCrypto {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.withOver6MillionCryptoUsersInB {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.section4 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 20px;
  padding: 20px 100px;
  background-color: #fff;
}
.frame47 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 11px;
}
.bRLAAccount {
  color: #0a1b23;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.createYourBRLAAccountAndStartU {
  color: #0a1b23;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 500px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame13 {
  width: 150px;
  height: min-content;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount2 {
  color: #0a1b23;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: min-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame46 {
  position: relative;
  height: 320px;
  align-self: stretch;
  align-items: flex-start;
  padding: 10px;
}
.tela_home2 {
  position: absolute;
  left: calc(50% - -0.0227px);
  top: 10px;
  width: 554.0453px;
  height: 300px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(-50%);
}
.footerFooter {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 42px;
  padding: 30px 44px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.contact {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.divRow {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 50px;
}
.form {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-end;
  gap: 11px;
}
.frame32 {
  width: 300px;
  height: min-content;
  align-items: center;
  gap: 15px;
}
.inputMobileWidth {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.name {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.inputMobileWidth2 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder2 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.eMail {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame33 {
  width: 300px;
  height: min-content;
  align-items: flex-start;
  gap: 15px;
}
.inputMobileWidth3 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder3 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.phone {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.inputMobileWidth4 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder4 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.company {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.textarea {
  width: 300px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.frame34 {
  width: 300px;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
}
.button {
  width: min-content;
  height: min-content;
  padding: 10px 10.67px 10px 9px;
  border-radius: 10px;
  background-color: #00dc84;
}
.submit {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.divHs_cos_wrapper_module_16619 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 15px;
}
.followBRLAOnSocial {
  color: #fff;
  font-size: 12.5px;
  line-height: 20px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divSocialGrid {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 24px;
  padding: 0 96px 0 0;
}
.frame {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.icon {
  width: 100%;
  height: 100%;
}
.frame3 {
  width: 21.88px;
  height: 25px;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.eMailInfoBrlaDigital {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.phone55113777742 {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.addressRuaUrussui125Cj84SaoPau {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divRow2 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 85px;
  padding: 0 15px;
}
.frame31 {
  width: min-content;
  height: min-content;
  padding: 10px;
}
.logocoin2 {
  width: 215px;
  height: 215px;
  background-image: url('../../../public/assets/logocoin2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer {
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 15px;
  padding: 4px 0;
}
.digitalAssetsAreSubjectToANumb {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
._2023BRLADigital {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: 12px;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 16px; /* Ajuste o tamanho da fonte conforme necessário */
  
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: transparent;
}
